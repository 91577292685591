import {
    useTranslate,
} from "@pankod/refine-core";
import {
    Create,
    Select,
    TextInput,
    useForm,
    useSelect,
    Text,
    Title,
    ListButton,
} from "@pankod/refine-mantine";
import { DatePickerInput } from '@mantine/dates';
import { RichTextEditor } from "@mantine/rte";
import { useState } from 'react';
import { useParams } from "react-router-dom";
import { 
    IconCalendar
} from '@tabler/icons-react';
//import { DateTime } from 'luxon';

export const SchoolTermCreate: React.FC = () => {
    const t = useTranslate();
    let { school_id , term_id} = useParams();

    const { saveButtonProps, getInputProps, errors } = useForm({
        refineCoreProps: {
            resource: `schools/${school_id}/terms`
        },
        initialValues: {
            name: "",
            start_date: new Date(), //DateTime.now().format('YYYY-MM-DD'), //@TODO: add initial value 
            end_date: new Date(), //@TODO: add initial value 
            year: new Date().getFullYear(),
            school_id,
            term_id: 0

        },
        validate: {
            start_date: (value) => (!value? "Start date is required" : null),
            year: (value) => !value ? "Year is required" : null,
            
        },
    });


    const { selectProps } = useSelect({
        resource: `schools/${school_id}/termtypes`,
        optionLabel: "name",
        optionValue: "id",
    });

    return (
        <Create 
            saveButtonProps={saveButtonProps}
            title={<Title order={3}>Add School Term</Title>} 
            headerButtons={
                <ListButton 
                    resourceNameOrRouteName={`schools/${school_id}/termtypes`}
                >Manage School term type</ListButton>
            }
        >
            <form>

                <Select
                    mt={8}
                    label={t("terms.fields.name")}
                    placeholder="Pick one term"
                    {...getInputProps("term_id")}
                    {...selectProps}
                />

                <DatePickerInput 
                    valueFormat="YYYY-MM-DD"
                    label={t("terms.fields.start_date")}
                    placeholder={t("terms.fields.start_date")}
                    icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                    {...getInputProps("start_date")} 
                />

                <DatePickerInput 
                    valueFormat="YYYY-MM-DD"
                    label={t("terms.fields.end_date")}
                    placeholder={t("terms.fields.end_date")}
                    icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                    {...getInputProps("end_date")}
                />
                
                <TextInput
                    mt={8}
                        label={t("terms.fields.year")}
                        placeholder={t("terms.fields.year")}
                    {...getInputProps("year")}
                />


            </form>
        </Create>
    );
};
