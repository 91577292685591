import {
    useTranslate,
    useOne
} from "@pankod/refine-core";
import {
    Create,
    Select,
    TextInput,
    useForm,
    useSelect,
    Text,
    Title
} from "@pankod/refine-mantine";
import { DatePickerInput } from '@mantine/dates';
import { RichTextEditor } from "@mantine/rte";
import { useState } from 'react';
import { useParams } from "react-router-dom";
import { 
    IconCalendar
} from '@tabler/icons-react';
//import { DateTime } from 'luxon';

export const StreamSubjectCreate: React.FC = () => {
    const t = useTranslate();
    let { schoolId, classId, streamId } = useParams();

    const { saveButtonProps, getInputProps, errors } = useForm({
        refineCoreProps: {
            resource: `schools/${schoolId}/classes/${classId}/streams/${streamId}/subjects`
        },
        initialValues: {
            stream_id: streamId,
            subject_id: null,
        },
        validate: {
            
        },
    });

    const { data : schoolData, isLoading : schoolIsLoading, isError : IsError } = useOne({
        resource: "schools",
        id: schoolId+ ""
    });


    const { data : classData } = useOne({
        resource: `schools/${schoolId}/classes`,
        id: classId+ ""
    });

    const { data : streamData, isLoading : streamIsLoading } = useOne({
        resource: `schools/${schoolId}/classes/${classId}/streams`,
        id: streamId+ ""
    });

    const { selectProps } = useSelect({
        resource: `schools/${schoolId}/subjects`,
        optionLabel: "name",
        optionValue: "id",
    });


    return (
        <Create 
            saveButtonProps={saveButtonProps}
            title={<Title order={3}>Add Subject to  {classData?.data.name} {streamData?.data.stream} - {schoolData?.data.name}</Title>} 
        >
            <form>
                <Select
                        mt={8}
                        label="Subject"
                        placeholder="Pick a subject"
                        {...getInputProps("subject_id")}
                        {...selectProps}
                    />
            </form>
        </Create>
    );
};
