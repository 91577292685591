import {
    useTranslate,
    useOne
} from "@pankod/refine-core";
import {
    Edit,
    Select,
    TextInput,
    useForm,
    useSelect,
    Text,
    Title
} from "@pankod/refine-mantine";
import { RichTextEditor } from "@mantine/rte";
import { useParams } from "react-router-dom";
import { DatePickerInput } from '@mantine/dates';
import { 
    IconCalendar
} from '@tabler/icons-react';

export const TermTypesEdit: React.FC = () => {
    const t = useTranslate();
    let { schoolId, id } = useParams();

    const { 
        saveButtonProps,
        getInputProps,
        errors,
        refineCore: { queryResult },
    } = useForm({
        initialValues: {
            name: "",
            short_name: ""
        },
        validate: {
          name: (value) => !value ? "Name is required" : null,
            short_name: (value) => !value ? "Short name is required" : null
        },
        refineCoreProps: {
            warnWhenUnsavedChanges: true,
            resource: `schools/${schoolId}/termtypes`,
            id,
            onMutationSuccess: (data, variables, context) => {
                console.log("data:", data);
            }
        },
    });

    const { data : termtypeData, isLoading : termtypeIsLoading, isError : termtypeIsError } = useOne({
        resource: `schools/${schoolId}/termtypes`,
        id: id + ""
    });

    console.log(queryResult?.data);
    return (
        <Edit 
        saveButtonProps={saveButtonProps}
        title={<Title order={3}>Edit School Term</Title>} 
        resource={`schools/${schoolId}/termtypes`}
        >
            <form>
            <TextInput
                    mt={8}
                        label="Term name"
                        placeholder="Term name"
                    {...getInputProps("name")}
                />
                <TextInput
                    mt={8}
                        label="Short name"
                        placeholder="Short name"
                        {...getInputProps("short_name")}
                />
            </form>
        </Edit>
    );
};
