import {
    useTranslate,
    useOne
} from "@pankod/refine-core";
import {
    Create,
    Select,
    TextInput,
    useForm,
    useSelect,
    Text,
    Title
} from "@pankod/refine-mantine";
import { DatePickerInput } from '@mantine/dates';
import { RichTextEditor } from "@mantine/rte";
import { useState } from 'react';
import { useParams } from "react-router-dom";
import { 
    IconCalendar
} from '@tabler/icons-react';
//import { DateTime } from 'luxon';

export const TermTypesCreate: React.FC = () => {
    const t = useTranslate();
    let { schoolId } = useParams();

    const { saveButtonProps, getInputProps, errors } = useForm({
        refineCoreProps: {
            resource: `schools/${schoolId}/termtypes`
        },
        initialValues: {
            name: '',
            short_name: ''
        },
        validate: {
            name: (value) => !value ? "Name is required" : null,
            short_name: (value) => !value ? "Short name is required" : null,
        },
    });

    const { data : schoolData, isLoading : schoolIsLoading, isError : IsError } = useOne({
        resource: "schools",
        id: schoolId+ ""
    });

    return (
        <Create 
            saveButtonProps={saveButtonProps}
            title={<Title order={3}>Add term to  {schoolData?.data.name}</Title>} 
        >
            <form>
                <TextInput
                    mt={8}
                        label="Term name"
                        placeholder="Term name"
                    {...getInputProps("name")}
                />
                <TextInput
                    mt={8}
                        label="Short name"
                        placeholder="Short name"
                    {...getInputProps("short_name")}
                />
            </form>
        </Create>
    );
};
