import React from "react";

import { Refine, Authenticated } from "@pankod/refine-core";
import {
  NotificationsProvider,
  notificationProvider,
  MantineProvider,
  Global,
  ReadyPage,
  ErrorComponent,
  useLocalStorage,
  ColorSchemeProvider,
  ColorScheme,
  DarkTheme,
  LightTheme,
  AuthPage,
} from "@pankod/refine-mantine";
import { DashboardPage } from "pages/dashboard";
//import dataProvider from "@pankod/refine-simple-rest";
import { dataProvider } from "./rest-data-provider";
import { MantineInferencer } from "@pankod/refine-inferencer/mantine";
import routerProvider from "@pankod/refine-react-router-v6";
import { useTranslation } from "react-i18next";
import { RefineKbarProvider } from "@pankod/refine-kbar";
import { Title, Sider, Layout, Header } from "components/layout";
import { OffLayoutArea } from "components/offLayoutArea";
import { authProvider } from "./authProvider";
import { SchoolList, SchoolCreate, SchoolEdit } from "pages/schools";
import { UserList, UserCreate } from "pages/users";
import { SchoolStaffList, SchoolStaffCreate, SchoolStaffEdit } from "pages/schoolstaff";
import { SchoolTermList, SchoolTermCreate, SchoolTermEdit } from "pages/schoolterms";
import { SchoolSettingsList, SchoolSettingsEdit } from "pages/schoolsettings";
import { SchoolClassList, SchoolClassEdit, SchoolClassCreate } from "pages/schoolclasses";
import { SchoolSubjectList, SchoolSubjectEdit, SchoolSubjectCreate } from "pages/schoolsubjects";
import { ClassStreamList, ClassStreamEdit, ClassStreamCreate } from "pages/classstreams";
import { StreamSubjectList, StreamSubjectEdit, StreamSubjectCreate } from "pages/streamsubjects";
import { TermTypesList, TermTypesEdit, TermTypesCreate } from "pages/termtypes";
import { TestPeriodTypeList, TestPeriodTypeEdit, TestPeriodTypeCreate } from "pages/testperiodtypes";
import { SchoolTestPeriodList, SchoolTestPeriodCreate, SchoolTestPeriodEdit } from "pages/schooltestperiods";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import routerBindings, {
  NavigateToResource,
  UnsavedChangesNotifier,
  CatchAllNavigate
} from "@refinedev/react-router-v6";

function App() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "light",
    getInitialValueInEffect: true,
  });
  const { t, i18n } = useTranslation();

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={colorScheme === "dark" ? DarkTheme : LightTheme}
        withNormalizeCSS
        withGlobalStyles
      >
        <Global styles={{ body: { WebkitFontSmoothing: "auto" } }} />
        <NotificationsProvider position="top-right">
          <RefineKbarProvider>
            <Refine
              //dataProvider={dataProvider("https://api.fake-rest.refine.dev")}
              dataProvider={{
                default: dataProvider(process.env.REACT_APP_API_BASE_URL + ""),
                baseEndPoi: dataProvider( process.env.REACT_APP_API_BASE_URL + "/schools"),
              }}
              notificationProvider={notificationProvider}
              ReadyPage={ReadyPage}
              //catchAll={<ErrorComponent />}
              resources={[
                {
                  name: "schools",
                  list: SchoolList,
                  edit: SchoolEdit,
                  show: MantineInferencer,
                  create: SchoolCreate,
                  canDelete: true
                },
                {
                  name: "users",
                  list: UserList,
                  edit: SchoolEdit,
                  show: MantineInferencer,
                  create: UserCreate,
                  canDelete: true,
                },
                {
                  name: "schools/:school_id/staff",
                  list: SchoolStaffList,
                  edit: SchoolStaffEdit,
                  show: MantineInferencer,
                  create: SchoolStaffCreate,
                  canDelete: true,
                  options:{
                    hide: true,
                    title: "School staff"
                  }
                },
                {
                  name: "schools/:school_id/terms",
                  list: SchoolTermList,
                  edit: SchoolTermEdit,
                  show: MantineInferencer,
                  create: SchoolTermCreate,
                  canDelete: true,
                  options:{
                    hide: true,
                    title: "School terms"
                  }
                },
                {
                  name: "schools/:schoolId/termtypes",
                  list: TermTypesList,
                  edit: TermTypesEdit,
                  show: MantineInferencer,
                  create: TermTypesCreate,
                  canDelete: true,
                  options:{
                    hide: true,
                    title: "School terms types"
                  }
                },
                {
                  name: "schools/:schoolId/testperiodtypes",
                  list: TestPeriodTypeList,
                  edit: TestPeriodTypeEdit,
                  show: MantineInferencer,
                  create: TestPeriodTypeCreate,
                  canDelete: true,
                  options:{
                    hide: true,
                    title: "School test period types"
                  }
                },
                {
                  name: "schools/:schoolId/settings",
                  list: SchoolSettingsList,
                  edit: SchoolSettingsEdit,
                  show: MantineInferencer,
                  create: SchoolTermCreate,
                  canDelete: true,
                  options:{
                    hide: true,
                    title: "School terms"
                  }
                },
                {
                  name: "schools/:schoolId/classes",
                  list: SchoolClassList,
                  edit: SchoolClassEdit,
                  show: MantineInferencer,
                  create: SchoolClassCreate,
                  canDelete: true,
                  options:{
                    hide: true,
                    title: "School Classes"
                  }
                },
                {
                  name: "schools/:schoolId/subjects",
                  list: SchoolSubjectList,
                  edit: SchoolSubjectEdit,
                  show: MantineInferencer,
                  create: SchoolSubjectCreate,
                  canDelete: true,
                  options:{
                    hide: true,
                    title: "School Classes"
                  }
                },
                {
                  name: "schools/:schoolId/classes/:classId/streams",
                  list: ClassStreamList,
                  edit: ClassStreamEdit,
                  show: MantineInferencer,
                  create: ClassStreamCreate,
                  canDelete: true,
                  options:{
                    hide: true,
                    title: "School Classes"
                  }
                },
                {
                  name: "schools/:schoolId/classes/:classId/streams/:streamId/subjects",
                  list: StreamSubjectList,
                  edit: StreamSubjectEdit,
                  show: MantineInferencer,
                  create: StreamSubjectCreate,
                  canDelete: true,
                  options:{
                    hide: true,
                    title: "Stream Subjects"
                  }
                },
                {
                  name: "schools/:school_id/terms/:termId/tests",
                  list: SchoolTestPeriodList,
                  edit: SchoolTestPeriodEdit,
                  show: MantineInferencer,
                  create: SchoolTestPeriodCreate,
                  canDelete: true,
                  options:{
                    hide: true,
                    title: "School terms"
                  }
                },
              ]}
              //Title={Title}
              Sider={Sider}
              Layout={Layout}
              //Header={Header}
              routerProvider={routerProvider}
              authProvider={authProvider}
              DashboardPage={DashboardPage}
              LoginPage={() => (
                <AuthPage 
                  wrapperProps={{
                    style: {
                      background: "radial-gradient(circle, rgba(55,44,255,1) 0%, rgba(4,4,4,1) 100%)",
                      position: "absolute",
                      top: "0px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }
                  }}
                  renderContent={(content: React.ReactNode) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h1 style={{color: '#ffffff'}}>Esomelo Web Admin</h1>
                        {content}
                        <h4 style={{color: '#ffffff'}}>&copy; {(new Date()).getFullYear()} Bodastage Solutions </h4>
                      </div>
                    );
                  }}
                />)}
              i18nProvider={i18nProvider}
              OffLayoutArea={OffLayoutArea}
              options={{
                warnWhenUnsavedChanges: true,
                syncWithLocation: true,
            }}
            >
            </Refine>
          </RefineKbarProvider>
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
