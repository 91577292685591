import {
    useTranslate,
} from "@pankod/refine-core";
import {
    Edit,
    Select,
    TextInput,
    useForm,
    useSelect,
    Text,
    PasswordInput,
    Title
} from "@pankod/refine-mantine";
import { RichTextEditor } from "@mantine/rte";
import { useParams } from "react-router-dom";

export const SchoolStaffEdit: React.FC = () => {
        const t = useTranslate();
        let { school_id, id } = useParams();

    const { 
        saveButtonProps,
        getInputProps,
        errors,
        refineCore: { queryResult },
    } = useForm({
        initialValues: {
            first_name: "",
            last_name: "",
            middle_name: "",
            work_phone: "",
            work_email: "",
            personal_email: "",
            date_of_birth: "",
            title: "Mr",
            type: "teacher",
            personal_phone: "",
            sex: "male",
            school_id,
            password: "",
            id
        },
        validate: {
            first_name: (value) => (value.length < 2 ? "Too short name" : null),
            password: (value) => (value.length < 6 ? "Too short. Must be more than 6 characters" : null),
            last_name: (value) => (value.length < 2 ? "Too short name" : null),
            personal_email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            sex: (value) => value.length <= 0 ? "Sex is required" : null,
        },
        refineCoreProps: {
            warnWhenUnsavedChanges: true,
            resource: `schools/${school_id}/staff`,
            id,
        },
    });

    console.log('queryResult:', queryResult);

    return (
        <Edit 
            title={<Title order={3}>Edit School Staff</Title>} 
            resource={`schools/${school_id}/staff`}
            saveButtonProps={saveButtonProps}>
            <form>
            <TextInput
                    mt={8}
                        label={t("staff.fields.first_name")}
                        placeholder={t("staff.fields.first_name")}
                    {...getInputProps("first_name")}
                />
                <TextInput
                    mt={8}
                        label={t("staff.fields.last_name")}
                        placeholder={t("staff.form.placeholders.last_name")}
                    {...getInputProps("last_name")}
                />
               
                <TextInput
                    mt={8}
                        label={t("staff.fields.middle_name")}
                        placeholder={t("staff.form.placeholders.middle_name")}
                    {...getInputProps("middle_name")}
                    
                />
               
               <TextInput
                    mt={8}
                        label={t("staff.fields.personal_phone")}
                        placeholder={t("staff.fields.personal_phone")}
                    {...getInputProps("personal_phone")}
                />
               
               <TextInput
                    mt={8}
                        label={t("staff.fields.work_phone")}
                        placeholder={t("staff.fields.work_phone")}
                    {...getInputProps("work_phone")}
                />
               
               <TextInput
                    mt={8}
                        label={t("staff.fields.work_email")}
                        placeholder={t("staff.fields.work_email")}
                    {...getInputProps("work_email")}
                />

                <TextInput
                    mt={8}
                        label={t("staff.fields.personal_email")}
                        placeholder={t("staff.fields.personal_email")}
                    {...getInputProps("personal_email")}
                />

                <TextInput
                    mt={8}
                        label={t("staff.fields.date_of_birth")}
                        placeholder={t("staff.fields.date_of_birth")}
                    {...getInputProps("date_of_birth")}
                />

                <TextInput
                    mt={8}
                        label={t("staff.fields.title")}
                        placeholder={t("staff.fields.title")}
                    {...getInputProps("title")}
                />


                <Select
                    mt={8}
                    label={t("staff.fields.sex")}
                    placeholder="Pick one gender"
                    {...getInputProps("school_type_id")}
                    defaultValue="2"
                    data={[
                        { label: "Male", value: "male" },
                        { label: "Female", value: "female" },
                    ]}
                />

            <Select
                    mt={8}
                    label={t("staff.fields.type")}
                    placeholder="Pick one role"
                    {...getInputProps("type")}
                    defaultValue="2"
                    data={[
                        { label: "Admin", value: "admin" },
                        { label: "Teacher", value: "teacher" },
                        { label: "Secretary", value: "secretary" },
                        { label: "Head teacher", value: "headteacher" },
                        { label: "Director", value: "director" },
                        { label: "Cook", value: "cook" },
                        { label: "Driver", value: "driver" },
                        { label: "Gatekeeper", value: "gatekeeper" },
                        { label: "Janitor", value: "janitor" },
                        { label: "Medic", value: "Medic" },
                    ]}
                />


                <PasswordInput
                    mt={8}
                        label={t("staff.fields.password")}
                        placeholder={t("staff.fields.password")}
                    {...getInputProps("password")}
                />


            </form>
        </Edit>
    );
};
