import {
    useTranslate,
} from "@pankod/refine-core";
import {
    Edit,
    Select,
    TextInput,
    useForm,
    useSelect,
    Text,
    Title
} from "@pankod/refine-mantine";
import { RichTextEditor } from "@mantine/rte";
import { useParams } from "react-router-dom";
import { DatePickerInput } from '@mantine/dates';
import { 
    IconCalendar
} from '@tabler/icons-react';

export const SchoolTestPeriodEdit: React.FC = () => {
    const t = useTranslate();
    let { school_id, id } = useParams();

    const { 
        saveButtonProps,
        getInputProps,
        errors,
        refineCore: { queryResult },
    } = useForm({
        initialValues: {
            name: "",
            start_date: new Date(),
            end_date: new Date(),
            year: new Date().getFullYear(),
            term_id: id,
            school_id
        },
        validate: {
          
        },
        refineCoreProps: {
            warnWhenUnsavedChanges: true,
            resource: `schools/${school_id}/terms`,
            id,
            onMutationSuccess: (data, variables, context) => {
                console.log("data:", data);
            }
        },
    });

    const { selectProps } = useSelect({
        resource: `schools/${school_id}/termtypes`,
        defaultValue: queryResult?.data?.data.id,
        optionLabel: "name",
        optionValue: "id",
    });

    const startDate = getInputProps("start_date");
    startDate.value = new Date(startDate.value);

    const endDate = getInputProps("end_date");
    endDate.value = new Date(endDate.value);

    return (
        <Edit 
        saveButtonProps={saveButtonProps}
        title={<Title order={3}>Edit School Term</Title>} 
        resource={`schools/${school_id}/terms`}
        >
            <form>
            <Select
                    mt={8}
                    label={t("terms.fields.name")}
                    placeholder="Pick one term"
                    {...getInputProps("term_id")}
                    {...selectProps}
                />

                <DatePickerInput 
                    valueFormat="YYYY-MM-DD"
                    label={t("terms.fields.start_date")}
                    placeholder={t("terms.fields.start_date")}
                    icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                    {...startDate} 
                />

                <DatePickerInput 
                    valueFormat="YYYY-MM-DD"
                    label={t("terms.fields.end_date")}
                    placeholder={t("terms.fields.end_date")}
                    icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                    {...endDate}
                />
                
                <TextInput
                    mt={8}
                        label={t("terms.fields.year")}
                        placeholder={t("terms.fields.year")}
                    {...getInputProps("year")}
                />
            </form>
        </Edit>
    );
};
