import { AuthProvider } from "@pankod/refine-core";
import { authenticateUser } from './services/api';

export const TOKEN_KEY = "auth";

export const authProvider: AuthProvider = {
    login: async ({ email, password }) => {
        try {
            const response = await authenticateUser (email, password);
                console.log(response.data);
                const user = response.data.data;
                
                //stringify used if setting the value in the local storage and the value is an object or array 
                localStorage.setItem(TOKEN_KEY, JSON.stringify(user));
                return Promise.resolve();
            }catch(error: any){
                return Promise.reject({
                    name: "Login Failed!",
                    message: "The email or password that you've entered doesn't match any account.",
                });
            };

       // return Promise.reject(new Error("username: admin, password: admin"));
    },
    logout: () => {
        localStorage.removeItem(TOKEN_KEY);
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token) {
            return Promise.resolve();
        }

        return Promise.reject();
    },
    getPermissions: () => {
        const auth = localStorage.getItem(TOKEN_KEY);
        if (auth) {
            const parsedUser = JSON.parse(auth);
            return Promise.resolve(parsedUser.sysUser);
        }
        return Promise.reject();
    },
    getUserIdentity: async () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (!token) {
            return Promise.reject();
        }

        return Promise.resolve({
            id: 1,
        });
    },
};
