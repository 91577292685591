import { Button, Grid, Skeleton, Container, Text, Progress, Card, Timeline, Divider, Group } from '@mantine/core';
import { IconCopy, IconBrandMastercard } from '@tabler/icons';
import { useNavigation, useList } from "@pankod/refine-core";
import {
    Create,
    List,
    NumberInput,
    TextInput,
    useForm,
    useModalForm as useDrawerForm,
} from "@pankod/refine-mantine";
import { useState } from 'react';

const child = <Skeleton height={140} radius="md" animate={false} />;
const numberFormat = Intl.NumberFormat('en-US');

export function DashboardPage() {

    const [showDrawer, setShowDrawer] = useState(false);
    const [collectionsDrawer, setCollectionsDrawer] = useState(false);
    const [payoutsDrawer, setPayoutsDrawer] = useState(false);

    const { list } = useNavigation();

    return (
        <Grid>
            <Grid.Col xs={4}>
                <Card
                    withBorder
                    radius="md"
                    p="xl"
                    sx={(theme) => ({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                    })}
                >

                </Card>
                <Card
                    withBorder
                    radius="md"
                    p="xl"
                    sx={(theme) => ({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                    })}
                >

                </Card>
            </Grid.Col>
            <Grid.Col xs={4}>
                <Card
                    withBorder
                    radius="md"
                    p="xl"
                    sx={(theme) => ({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                    })}
                >

                </Card>
                <Card
                    withBorder
                    radius="md"
                    p="xl"
                    sx={(theme) => ({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                    })}
                >

                </Card>
            </Grid.Col>
            <Grid.Col xs={4}>
                <Card
                    withBorder
                    radius="md"
                    p="xl"
                    sx={(theme) => ({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                    })}
                >

                </Card>
            </Grid.Col>
            <Grid.Col xs={12}>
                <Card
                    withBorder
                    radius="md"
                    p="xl"
                    style={{ textAlign: "left" }}
                    sx={(theme) => ({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                    })}
                >

                </Card>
            </Grid.Col>
            <Grid.Col xs={8}>
                <Card
                    withBorder
                    radius="md"
                    p="xl"
                    sx={(theme) => ({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                    })}
                >
                </Card>
            </Grid.Col>
            <Grid.Col xs={4}>
                <Card
                    withBorder
                    radius="md"
                    p="xl"
                    sx={(theme) => ({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                    })}
                >

                </Card>


            </Grid.Col>
        </Grid>
    );
}