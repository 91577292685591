import {
    useTranslate,
    useOne
} from "@pankod/refine-core";
import {
    Edit,
    Select,
    TextInput,
    useForm,
    useSelect,
    Text,
    Title
} from "@pankod/refine-mantine";
import { RichTextEditor } from "@mantine/rte";
import { useParams } from "react-router-dom";

export const SchoolSettingsEdit: React.FC = () => {
    const t = useTranslate();
    let { schoolId, id } = useParams();

    const { 
        saveButtonProps,
        getInputProps,
        errors,
        refineCore: { queryResult },
    } = useForm({
        initialValues: {
            name: "",
            setting_id: id,
            school_id: schoolId,
            value: "",
            id: id
        },
        validate: {
            value: (value) => (value.length === 0 ? "Value is too short" : null),
        },
        refineCoreProps: {
            warnWhenUnsavedChanges: true,
            resource: `schools/${schoolId}/settings`,
            id
        },
    });

    const { data : settingData, isLoading : termIsLoading, isError : termIsError } = useOne({
        resource: `settings`,
        id: id+ ""
    });


    return (
        <Edit 
            title={<Title order={3}>Update School Setting - {settingData ? settingData?.data.label : ''}</Title>} 
            resource={`schools/${schoolId}/settings`}
            saveButtonProps={saveButtonProps}>
            <form>
               
                <TextInput
                    mt={8}
                        label={t("settings.fields.value")}
                        placeholder={t("settings.form.placeholders.value")}
                    {...getInputProps("value")}
                    
                />
            </form>
        </Edit>
    );
};
