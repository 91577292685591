import {
    useTranslate,
} from "@pankod/refine-core";
import {
    Create,
    Select,
    TextInput,
    useForm,
    useSelect,
    Text,
} from "@pankod/refine-mantine";
import { RichTextEditor } from "@mantine/rte";
import { useState } from 'react';

export const UserCreate: React.FC = () => {
        const t = useTranslate();
    
    const { saveButtonProps, getInputProps, errors } = useForm({
        initialValues: {
            first_name: "",
            last_name: "",
            middle_name: "",
            title: "",
            phone: "",
            address: "",
            sex: "",
            role: "guest",
            email: "",
            password: ""
        },
        validate: {
            first_name: (value) => (value.length < 2 ? "First name is too short" : null),
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            last_name: (value) => value.length <= 0 ? "Last name is required" : null,
            password: (value) => value.length < 6 ? "Password is too short. Password must be longer than 6 characters." : null,
        },
    });

    return (
        <Create saveButtonProps={saveButtonProps}>
            <form>
                <TextInput
                    mt={8}
                        label={t("users.fields.first_name")}
                        placeholder={t("users.fields.first_name")}
                    {...getInputProps("first_name")}
                />

                <TextInput
                    mt={8}
                        label={t("users.fields.last_name")}
                        placeholder={t("users.fields.last_name")}
                    {...getInputProps("last_name")}
                />
               
                <TextInput
                    mt={8}
                        label={t("users.fields.email")}
                        placeholder={t("users.fields.email")}
                    {...getInputProps("email")}
                    
                />
               
               <TextInput
                    mt={8}
                        label={t("users.fields.phone")}
                        placeholder={t("users.fields.phone")}
                    {...getInputProps("phone")}
                />
           

                <Select
                    mt={8}
                    label={t("users.fields.sex")}
                    placeholder="Pick one sex"
                    {...getInputProps("sex")}
                    defaultValue="2"
                    data={[
                        { label: "Male", value: "male" },
                        { label: "Female", value: "female" },
                    ]}
                />
           
           <TextInput
                    mt={8}
                        label={t("users.fields.password")}
                        placeholder={t("users.fields.password")}
                    {...getInputProps("password")}
                />

            </form>
        </Create>
    );
};
