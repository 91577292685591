import React from "react";
import { IResourceComponentsProps, 
    useTranslate } from "@pankod/refine-core";
import { useTable, ColumnDef, flexRender } from "@pankod/refine-react-table";
import {
    ScrollArea,
    List,
    Table,
    Pagination,
    Group,
    EditButton,
    ShowButton,
    DeleteButton,
    Button,
    Menu,
    Text
} from "@pankod/refine-mantine";
import { ISchool } from "interfaces";
import { IconSettings, IconSearch, IconPhoto, IconMessageCircle, IconTrash, IconArrowsLeftRight } from '@tabler/icons-react';


export const UserList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const columns = React.useMemo<ColumnDef<any>[]>(
        () => [

            {
                id: "id",
                header: t("users.fields.id"),
                accessorKey: "id"
            },
            {
                id: "name",
                header: t("users.fields.name"),
                accessorFn: row => `${row.first_name} ${row.last_name}`
            },
            {
                id: "email",
                header: t("users.fields.email"),
                accessorKey: "email"
            },
            
            {
                id: "country",
                header: t("users.fields.country"),
                accessorKey: "country"
            },
            
            {
                id: "actions",
                accessorKey: "id",
                header: "Actions",
                cell: function render({ getValue }) {
                    return (
                        <Group spacing="xs" noWrap>
                            <ShowButton
                                hideText
                                recordItemId={getValue() as string}
                            />
                            <EditButton
                                hideText
                                recordItemId={getValue() as string}
                            />
                            <DeleteButton
                                hideText
                                recordItemId={getValue() as string}
                            />
                            <Menu shadow="md" width={200}>
                                <Menu.Target>
                                    <Button variant="filled">More</Button>
                                </Menu.Target>

                                <Menu.Dropdown>
                                    <Menu.Label>More options</Menu.Label>
                                    <Menu.Item icon={<IconSettings size={14} />}>Map to staff</Menu.Item>
                                    <Menu.Item icon={<IconSettings size={14} />}>Add school role</Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        </Group>
                    );
                },
            },
        ],
        [],
    );

    const {
        getHeaderGroups,
        getRowModel,
        setOptions,
        refineCore: {
            setCurrent,
            pageCount,
            current,
            tableQueryResult: { data: tableData },
        },
    } = useTable({
        columns,
    });

    setOptions((prev) => ({
        ...prev,
        meta: {
            ...prev.meta,
        },
    }));

    return (
        <List>
            <ScrollArea>
                <Table highlightOnHover>
                    <thead>
                        {getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th key={header.id}>
                                            {!header.isPlaceholder &&
                                                flexRender(
                                                    header.column.columnDef
                                                        .header,
                                                    header.getContext(),
                                                )}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {getRowModel().rows.map((row) => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext(),
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </ScrollArea>
            <br />
            <Pagination
                position="right"
                total={pageCount}
                page={current}
                onChange={setCurrent}
            />
        </List>
    );
};
