import React from "react";
import { IResourceComponentsProps, 
    useTranslate,
    useOne } from "@pankod/refine-core";
import { useTable, ColumnDef, flexRender } from "@pankod/refine-react-table";
import {
    ScrollArea,
    List,
    Table,
    Pagination,
    Group,
    EditButton,
    ShowButton,
    DeleteButton,
    Button,
    Menu,
    Text,
    Title
} from "@pankod/refine-mantine";
import { ISchool } from "interfaces";
import { IconSettings, IconSearch, IconPhoto, IconMessageCircle, IconTrash, IconArrowsLeftRight } from '@tabler/icons-react';
import { useSearchParams, useParams } from "react-router-dom";
import { useParsed } from "@refinedev/core";

export const SchoolTestPeriodList: React.FC<IResourceComponentsProps> = (props) => {
    const t = useTranslate();
    let { school_id, termId } = useParams();
    const { params } =  useParsed();


    const columns = React.useMemo<ColumnDef<any>[]>(
        () => [

            {
                id: "id",
                header: t("terms.fields.id"),
                accessorKey: "id"
            },
            {
                id: "name",
                header: t("terms.fields.name"),
                accessorKey: "name"
            },
            {
                id: "start_date",
                header: t("terms.fields.start_date"),
                accessorKey: "start_date"
            },
            {
                id: "end_date",
                header: t("terms.fields.end_date"),
                accessorKey: "end_date"
            },
            {
                id: "year",
                header: t("terms.fields.year"),
                accessorKey: "year"
            },

            {
                id: "actions",
                accessorKey: "id",
                header: "Actions",
                cell: function render({ getValue }) {
                    return (
                        <Group spacing="xs" noWrap>
                            <ShowButton
                                hideText
                                recordItemId={getValue() as string}
                            />
                            <EditButton
                                hideText
                                recordItemId={getValue() as string}
                                onClick={ () => window.location.href="/schools/" +  school_id + "/terms/" + termId + "/edit/" + getValue()}
                            />
                            <DeleteButton
                                hideText
                                recordItemId={getValue() as string}
                            />
                           
                        </Group>
                    );
                },
            },
        ],
        [],
    );

    
    const { data : termData, isLoading : termIsLoading, isError : termIsError } = useOne({
        resource: `schools/${school_id}/terms`,
        id: termId + "",
    });

    const { 
        data : schoolData, 
        //isLoading : schoolIsLoading, 
        //isError : schoolIsLoading
    } = useOne({
        resource: `schools`,
        id: school_id + "",
    });

    const {
        getHeaderGroups,
        getRowModel,
        setOptions,
        refineCore: {
            setCurrent,
            pageCount,
            current,
            tableQueryResult: { data: tableData },
        },
    } = useTable({
        columns,
        refineCoreProps: {
            resource: `schools/${school_id}/terms/${termId}/tests`,
        }
    });

    setOptions((prev) => ({
        ...prev,
        meta: {
            ...prev.meta,
        },
    }));

    return (
        <List 
            title={<Title order={3}>
                    School Test Periods - {termData?.data.name} - {termData?.data.year} - {schoolData?.data?.name}
                </Title>} 
            canCreate={true}
            resource={`schools/${school_id}/terms/${termId}/tests`}
        >
            <ScrollArea>
                <Table highlightOnHover>
                    <thead>
                        {getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th key={header.id}>
                                            {!header.isPlaceholder &&
                                                flexRender(
                                                    header.column.columnDef
                                                        .header,
                                                    header.getContext(),
                                                )}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {getRowModel().rows.map((row) => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext(),
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </ScrollArea>
            <br />
            <Pagination
                position="right"
                total={pageCount}
                page={current}
                onChange={setCurrent}
            />
        </List>
    );
};
