import React from "react";
import { IResourceComponentsProps, 
    useOne,
    useTranslate } from "@pankod/refine-core";
import { useTable, ColumnDef, flexRender } from "@pankod/refine-react-table";
import {
    ScrollArea,
    List,
    Table,
    Pagination,
    Group,
    EditButton,
    ShowButton,
    DeleteButton,
    Button,
    Menu,
    Text,
    Title
} from "@pankod/refine-mantine";
import { ISchool } from "interfaces";
import { 
    IconSettings, 
    IconSearch, 
    IconPhoto, 
    IconMessageCircle, 
    IconTrash, 
    IconArrowsLeftRight 
} from '@tabler/icons-react';
import { useGo, useGetToPath } from "@refinedev/core";
import { useParams } from "react-router-dom";

export const SchoolSettingsList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const go = useGo();
    const getToPath = useGetToPath();
    let { schoolId } = useParams();


    const columns = React.useMemo<ColumnDef<any>[]>(
        () => [

            {
                id: "id",
                header: t("settings.fields.id"),
                accessorKey: "setting_id"
            },
            {
                id: "name",
                header: t("settings.fields.name"),
                accessorKey: "name"
            },
            {
                id: "value",
                header: t("settings.fields.value"),
                accessorKey: "value"
            },
        
            {
                id: "actions",
                accessorKey: "id",
                header: "Actions",
                cell: function render({ row, getValue }) {

                    console.log(row);
                    const _id = getValue() ? getValue() + "-" + row.original.setting_id : "0-" + row.original.setting_id;
                    return (
                        <Group spacing="xs" noWrap>
                           
                            <EditButton
                                hideText
                                resourceNameOrRouteName={`schools/${schoolId}/settings`}
                                recordItemId={`${row.original.setting_id}`}
                            />
                        </Group>
                    );
                },
            },
        ],
        [],
    );

    const {
        getHeaderGroups,
        getRowModel,
        setOptions,
        refineCore: {
            setCurrent,
            pageCount,
            current,
            tableQueryResult: { data: tableData },
        },
    } = useTable({
        columns,
        refineCoreProps: {
            resource: `schools/${schoolId}/settings`,
        }
    });

    setOptions((prev) => ({
        ...prev,
        meta: {
            ...prev.meta,
        },
    }));


    const { 
        data : schoolData
    } = useOne({
        resource: `schools`,
        id: schoolId + "",
    });

    return (
        <List
        title={<Title order={3}>School Settings - {schoolData?.data.name}</Title>} 
        canCreate={false}
        resource={`schools/${schoolId}/settings`}
        >
            <ScrollArea>
                <Table highlightOnHover>
                    <thead>
                        {getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th key={header.id}>
                                            {!header.isPlaceholder &&
                                                flexRender(
                                                    header.column.columnDef
                                                        .header,
                                                    header.getContext(),
                                                )}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {getRowModel().rows.map((row) => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext(),
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </ScrollArea>
            <br />
            <Pagination
                position="right"
                total={pageCount}
                page={current}
                onChange={setCurrent}
            />
        </List>
    );
};
