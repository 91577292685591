import React from "react";
import { IResourceComponentsProps, 
    useTranslate,
    useOne
} from "@pankod/refine-core";
import { useTable, ColumnDef, flexRender } from "@pankod/refine-react-table";
import {
    ScrollArea,
    List,
    Table,
    Pagination,
    Group,
    EditButton,
    ShowButton,
    DeleteButton,
    Button,
    Menu,
    Text,
    Title
} from "@pankod/refine-mantine";
import { ISchool } from "interfaces";
import { IconSettings, IconSearch, IconPhoto, IconMessageCircle, IconTrash, IconArrowsLeftRight } from '@tabler/icons-react';
import { useSearchParams, useParams } from "react-router-dom";
import { useParsed } from "@refinedev/core";

export const TermTypesList: React.FC<IResourceComponentsProps> = (props) => {
    const t = useTranslate();
    let { schoolId, classId, streamId } = useParams();
    const { params } =  useParsed();


    const columns = React.useMemo<ColumnDef<any>[]>(
        () => [

            {
                id: "id",
                header: t("termtypes.fields.id"),
                accessorKey: "id"
            },
            {
                id: "name",
                header: t("termtypes.fields.name"),
                accessorKey: "name"
            },
            {
                id: "short_name",
                header: t("termtypes.fields.short_name"),
                accessorKey: "short_name"
            },
            {
                id: "actions",
                accessorKey: "id",
                header: "Actions",
                cell: function render({ getValue }) {
                    return (
                        <Group spacing="xs" noWrap>
                            <EditButton
                                hideText
                                recordItemId={getValue() as string}
                                //onClick={ () => window.location.href="/schools/" +  school_id + "/terms/" + termId + "/edit/" + getValue()}
                                resourceNameOrRouteName={`schools/${schoolId}/termtypes`}
                            />

                            <DeleteButton
                                hideText
                                recordItemId={getValue() as string}
                                resourceNameOrRouteName={`schools/${schoolId}/termtypes`}

                            />
                           
                        </Group>
                    );
                },
            },
        ],
        [],
    );

    const {
        getHeaderGroups,
        getRowModel,
        setOptions,
        refineCore: {
            setCurrent,
            pageCount,
            current,
            tableQueryResult: { data: tableData },
        },
    } = useTable({
        columns,
        refineCoreProps: {
            resource: `schools/${schoolId}/termtypes`,
        }
    });

    setOptions((prev) => ({
        ...prev,
        meta: {
            ...prev.meta,
        },
    }));

    const { data : schoolData, isLoading : schoolIsLoading, isError : termIsError } = useOne({
        resource: "schools",
        id: schoolId+ ""
    });


    return (
        <List 
            title={<Title order={3}>Term Types in {schoolData?.data.name} </Title>} 
            canCreate={true}
            resource={`schools/${schoolId}/termtypes`}
        >
            <ScrollArea>
                <Table highlightOnHover>
                    <thead>
                        {getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th key={header.id}>
                                            {!header.isPlaceholder &&
                                                flexRender(
                                                    header.column.columnDef
                                                        .header,
                                                    header.getContext(),
                                                )}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {getRowModel().rows.map((row) => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext(),
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </ScrollArea>
            <br />
            <Pagination
                position="right"
                total={pageCount}
                page={current}
                onChange={setCurrent}
            />
        </List>
    );
};
